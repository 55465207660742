exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-to-videos-js": () => import("./../../../src/pages/how-to-videos.js" /* webpackChunkName: "component---src-pages-how-to-videos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-vantage-fit-employees-index-js": () => import("./../../../src/pages/vantage-fit/employees/index.js" /* webpackChunkName: "component---src-pages-vantage-fit-employees-index-js" */),
  "component---src-pages-vantage-fit-hr-administrators-index-js": () => import("./../../../src/pages/vantage-fit/hr-administrators/index.js" /* webpackChunkName: "component---src-pages-vantage-fit-hr-administrators-index-js" */),
  "component---src-pages-vantage-fit-index-js": () => import("./../../../src/pages/vantage-fit/index.js" /* webpackChunkName: "component---src-pages-vantage-fit-index-js" */),
  "component---src-pages-vantage-perks-employeebenefits-fundamentals-js": () => import("./../../../src/pages/vantage-perks/employeebenefits-fundamentals.js" /* webpackChunkName: "component---src-pages-vantage-perks-employeebenefits-fundamentals-js" */),
  "component---src-pages-vantage-perks-employees-index-js": () => import("./../../../src/pages/vantage-perks/employees/index.js" /* webpackChunkName: "component---src-pages-vantage-perks-employees-index-js" */),
  "component---src-pages-vantage-perks-hr-administrators-index-js": () => import("./../../../src/pages/vantage-perks/hr-administrators/index.js" /* webpackChunkName: "component---src-pages-vantage-perks-hr-administrators-index-js" */),
  "component---src-pages-vantage-perks-index-js": () => import("./../../../src/pages/vantage-perks/index.js" /* webpackChunkName: "component---src-pages-vantage-perks-index-js" */),
  "component---src-pages-vantage-pulse-employees-index-js": () => import("./../../../src/pages/vantage-pulse/employees/index.js" /* webpackChunkName: "component---src-pages-vantage-pulse-employees-index-js" */),
  "component---src-pages-vantage-pulse-hr-administrators-index-js": () => import("./../../../src/pages/vantage-pulse/hr-administrators/index.js" /* webpackChunkName: "component---src-pages-vantage-pulse-hr-administrators-index-js" */),
  "component---src-pages-vantage-pulse-index-js": () => import("./../../../src/pages/vantage-pulse/index.js" /* webpackChunkName: "component---src-pages-vantage-pulse-index-js" */),
  "component---src-pages-vantage-recognition-employeerecognition-fundamentals-js": () => import("./../../../src/pages/vantage-recognition/employeerecognition-fundamentals.js" /* webpackChunkName: "component---src-pages-vantage-recognition-employeerecognition-fundamentals-js" */),
  "component---src-pages-vantage-recognition-employees-index-js": () => import("./../../../src/pages/vantage-recognition/employees/index.js" /* webpackChunkName: "component---src-pages-vantage-recognition-employees-index-js" */),
  "component---src-pages-vantage-recognition-hr-administrators-index-js": () => import("./../../../src/pages/vantage-recognition/hr-administrators/index.js" /* webpackChunkName: "component---src-pages-vantage-recognition-hr-administrators-index-js" */),
  "component---src-pages-vantage-recognition-index-js": () => import("./../../../src/pages/vantage-recognition/index.js" /* webpackChunkName: "component---src-pages-vantage-recognition-index-js" */),
  "component---src-pages-vantage-recognition-managers-index-js": () => import("./../../../src/pages/vantage-recognition/managers/index.js" /* webpackChunkName: "component---src-pages-vantage-recognition-managers-index-js" */),
  "component---src-pages-vantage-redemption-employees-index-js": () => import("./../../../src/pages/vantage-redemption/employees/index.js" /* webpackChunkName: "component---src-pages-vantage-redemption-employees-index-js" */),
  "component---src-pages-vantage-redemption-hr-administrators-index-js": () => import("./../../../src/pages/vantage-redemption/hr-administrators/index.js" /* webpackChunkName: "component---src-pages-vantage-redemption-hr-administrators-index-js" */),
  "component---src-pages-vantage-redemption-index-js": () => import("./../../../src/pages/vantage-redemption/index.js" /* webpackChunkName: "component---src-pages-vantage-redemption-index-js" */),
  "component---src-templates-detail-page-template-js": () => import("./../../../src/templates/detail-page-template.js" /* webpackChunkName: "component---src-templates-detail-page-template-js" */),
  "component---src-templates-view-all-template-js": () => import("./../../../src/templates/view-all-template.js" /* webpackChunkName: "component---src-templates-view-all-template-js" */)
}

